<script lang="ts">
</script>

<div class="text-content">
  <h2 class="text-headline">Terms of Service</h2>
  <h3 class="text-large text-headline">Registration and Payment</h3>
  <ul class="textList">
    <li>
      Summerbo.at reserves the right to refuse admission based on previous
      behavior at conventions or parties.
    </li>
    <li>
      To become an attendee of the party you have to register yourself with the
      online registration tool available on our website.
    </li>
    <li>
      After registration your entry will be manually screened and approved or
      denied. Payment information will follow later on via email.
    </li>
    <li>
      Attendees need to be at least 18 years old on the day of the party. We
      check your ID or Passport so you need to present it at check-in time.
    </li>
    <li>
      If you want to transfer your ticket, both persons are required to send an
      email to <a href="mailto:registration@summerbo.at"
        >registration@summerbo.at</a
      > and express their desire to transfer the ticket. It is only valid after receiving
      the confirmation from our registration team.
    </li>
    <li>
      Communication with our registration system is secured by SSL data
      encryption and the website is HSTS preloaded.
    </li>
    <li>
      We obey the German data protection laws and GDPR so your data will be
      treated confidentially.
    </li>
    <li>
      We do not share any data with third parties, unless required by law.
    </li>
    <li>
      You agree to receive emails from us regarding your registration and
      important information about the party itself. We will never send any
      emails that are unrelated to our party.
    </li>
    <li>
      Summerbo.at is a public event and you acknowledge that you are required to
      wear a badge with your nickname and country.
    </li>
    <li>
      You agree to be included on the public attendee list if you do not opt-out
      of the list during registration or in your user area.
    </li>
  </ul>

  <p>
    While we are doing everything we can to let you enjoy safely our party, we
    can not be responsible for any consequences. If being associated with being
    a furry in the public is harming you, please reconsider going to the party.
  </p>
</div>
