<script lang="ts">
</script>

<div class="text-content">
  <h2 class="text-headline">Glympse Map</h2>
  <p>Watch your friends and fellow attendees on the way to (and maybe from) Eurofurence!</p>

  <p>If you want to put yourself on the map, simply download the Glympse app and join the tag "!Eurofurence".</p>

  <div>
    <iframe title="Glympse Tag" id="glympse" src="https://glympse.com/ext/!eurofurence?twt=Eurofurence&amp;hash=ef26" scrolling="no" marginheight="0" marginwidth="0" frameborder="0"></iframe>
  </div>
</div>