<div class="textContent">
  <h2 id="conduct" class="text-headline"><strong>Conduct</strong></h2>

  <p>
    We want to build the best party ever for you. To make sure that everything
    runs smoothly and fun we have a few rules for everyone. Please make yourself
    familiar with them. Most are common sense but we still want to be sure you
    know what to expect.
  </p>
  <p>
    The rules will be enforced by the Security Staff. Please listen to them at
    all times and follow their requests. If you have any problem with one of the
    security members, please let one of the party staff members know.
  </p>
  <ul class="textList">
    <li>Always treat each other with respect.</li>
    <li>
      Always follow the directions by the boat or party staff and security.
    </li>
    <li>You are liable for any damage you might cause.</li>
    <li>We do not accept any kind of harassment, period.</li>
  </ul>
  <p>
    While we are on a boat we are still visible for the other public. Please
    behave like that and give the best impression.
  </p>

  <h3 class="textLarge"><strong>Registration</strong></h3>
  <ul class="textList">
    <li>You have to be at least 18 on the day of the party to attend.</li>
    <li>Only staff and registered attendees are allowed on the boat.</li>
    <li>
      Attendees are required to wear their badge and lanyard at all times during
      the party.
    </li>
    <li>Badges need to be shown upon request by staff and while boarding.</li>
  </ul>
  <h3 class="textLarge"><strong>Boat Rules</strong></h3>
  <ul class="textList">
    <li>
      It is not allowed to climb over any railings or walk on parts that are off
      limit for attendees.
    </li>
    <li>It is not allowed to bring your own beverages or food to the party.</li>
    <li>It is not allowed to throw stuff in the water from the boat.</li>
    <li>It is not allowed to smoke outside of the designated smoke area.</li>
    <li>It is not allowed to bring any dogs, cats or other pets onboard.</li>
    <li>
      Please do not use personal music players while on board. We have music for
      a reason.
    </li>
  </ul>
  <h3 class="textLarge"><strong>Clothing and Decency</strong></h3>
  <ul class="textList">
    <li>
      Nudity exceeding the equivalent of bathing suits is not allowed on the
      boat.
    </li>
    <li>
      Due to the possible heat of the outerdeck floor it is not allowed to walk
      barefoot on the entire boat.
    </li>
    <li>
      Sexual behavior that goes beyond a mere display of affection is not
      allowed in public.
    </li>
    <li>
      Display of fetish gear is not permitted in public, even if it is part of a
      costume.
    </li>
  </ul>
  <h3 class="textLarge"><strong>Alcohol and Drugs</strong></h3>
  <ul class="textList">
    <li>It is forbidden to bring or use drugs to the party.</li>
    <li>
      If we find you in the possession of or distributing illegal or controlled
      substances we will notify the local authorities and Eurofurence.
    </li>
    <li>Smoking and vaping is only allowed in the designated smoking areas.</li>
  </ul>
  <h3 class="textLarge"><strong>Photography and Video</strong></h3>
  <p>
    Everyone is welcome to take pictures and capture memories for private use.
    Selling or using the videos and pictures for profitable companies is not
    allowed.
  </p>
  <ul class="textList">
    <li>
      There will be a professional camera team capturing the party with video
      and pictures. This is for all the public areas.
    </li>
    <li>
      While boarding it is possible to let us take a picture, if you agree with
      that it also means your picture will be online on our website to view and
      download shortly after the party.
    </li>
  </ul>
  <p>
    Please keep in mind that on and around our event you also have to comply to the
    rules and regulations of Eurofurence that you can find here: <a
      href="https://help.eurofurence.org/legal/roc"
      >Eurofurence Rules of Conduct</a
    >.
  </p>
</div>
