<script lang="ts">
</script>

<div class="text-content">
  <h2 class="text-headline">2023, Cursed Cruise</h2>

  <p class="text-large">Under Construction</p>
  <p>
    We're currently working on improving the archives from previous years, enjoy
    some photos in the meantime.
  </p>
  <div class="image-gallery">
    <img
      src="/img/2019/photo1-compressed.jpg"
      alt="Fursuiters in a boat interior lit by colorful lights"
    />
    <img
      src="/img/2019/photo2-compressed.jpg"
      alt="Fursuiters on the deck of a boat holding a sign which says 2019: Hot Summer Nights"
    />
    <img
      src="/img/2019/photo3-compressed.jpg"
      alt="Fursuiters socialising on the deck of a boat"
    />
    <img
      src="/img/2019/photo4-compressed.jpg"
      alt="People dancing infront of a DJ booth, with a mirrored ceiling reflecting them"
    />
    <img
      src="/img/2019/photo5-compressed.jpg"
      alt="Two fursuiters sit on the upper deck of the boat whilst others wait to board"
    />
    <img
      src="/img/2019/photo6-compressed.jpg"
      alt="A partygoer poses in silent-disco headphones on their fursuit's head"
    />
  </div>
</div>

<style>
  .image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    column-gap: 1rem;
    row-gap: 1rem;
    margin-bottom: 2rem;
  }
</style>
