<script lang="ts">
  import Accordion from "./components/Accordion.svelte";
</script>

<div class="faq textContent">
  <h2 class="text-headline text-large">Frequently Asked Questions</h2>
  <Accordion question="What kind of things can we do at the party?">
    <p>
      There will be a <strong>dancefloor</strong>, multiple <strong>bars</strong>,
      <strong>lounge area</strong> and <strong>a fursuit lounge</strong>. The
      outside decks provide for a great place to chill, have a drink and mingle
      with the other guests. Games and events including fursuit limbo,
      photoshoot, and more will be organized. The best way to stay tuned for
      announcements and events is via the
      <a href="https://t.me/summerboatinfo" rel="noopener" target="_blank"
        >telegram info channel</a
      >
      or our
      <a
        href="https://www.twitter.com/summerbo_at"
        rel="noopener"
        target="_blank">Twitter</a
      >.
    </p>
  </Accordion>
  <Accordion question="Is this party affiliated with Eurofurence?"
    ><p>
      We are part of, officially acknowledged and supported by Eurofurence. We
      want to offer early arrival attendees a party to kick off their convention
      experience! Ticket payment will be handled by the official Eurofurence
      registration system.
    </p></Accordion
  >
  <Accordion question="How much does the party cost?"
    ><p>
      You can purchase a <em>Normal</em> ticket for <strong>TBD&euro;</strong>.
      We also offer a <abbr>VIP</abbr>
      upgrade for additional <strong>TBD&euro;</strong>. Not only will this
      include a special gift and a special <abbr>VIP</abbr> badge, but you also help
      us to make the party even more awesome!
    </p></Accordion
  >
  <Accordion question="What is the minimum age for attending?"
    ><p>
      You need to be at least 18 years old on the day of the party. You will be
      required to present a legal photo ID or passport at badge pickup time.
    </p></Accordion
  >
  <Accordion question="Can I order my VIP Upgrade later?"
    ><p>
      Yes! Simply login with your account details, tick
      the box for VIP upgrade in the user area and
      click submit. You will receive an email as a confirmation.
    </p></Accordion
  >
  <Accordion question="How do I get my badge?"
    ><p>
      You will find our Info & Check-in desk at Überseebrücke. Please make sure you bring your ID or Passport with you. The badge
      is your ticket to the party so please do not lose it!<br />
      Keep in mind: Badge pickup and Check-in
      <em><strong>before</strong></em> boarding!
    </p></Accordion
  >
  <Accordion question="Where do the profits of the party go to?"
    ><p>
      Our money is managed by the legal entity Eurofurence e.V. which is
      unable to take any profits for other purposes than the event itself.
      Leftover money will be rolled over as a reserve for next years party
      or donated to charity, which we'll be announcing transparently.
      In previous years, we donated ALL the money we made from VIP upgrades to charity,
      but due to the move to Hamburg and global crises, we'll actually use it to support
      the party to be able to not raise prices too much for our regular attendees.
    </p></Accordion
  >

  <Accordion question="How did the party come to be?">
    <p>
      In <a href="https://2019.summerbo.at/" target="_blank">2019</a> some friends
      wanted to do something special for celebrating the 25th edition of Eurofurence
      and the 5th edition in Berlin, the city that knows how to party! So why not
      on a boat? Cruising on the beautiful Spree River through Berlin. We wanted
      a chill atmosphere for partying and suiting up.
    </p>
  </Accordion>
</div>

<style>
</style>
